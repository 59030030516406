export const checkTypeList = [{
  key: '==',
  label: '相等'
},
{
  key: 'in',
  label: '在匹配值中'
},
{
  key: 'mix',
  label: '有交集'
}]

export const sourceDataList = [{
  key: 'data',
  label: '数据'
},
{
  key: 'user',
  label: '用户信息'
},
{
  key: 'value',
  label: '指定值'
}]

export const getDataSourceKeysConfig = (statusGroupList) => {
  let statusGroupMap = {}
  statusGroupList.forEach(v => {
    statusGroupMap[v.groupKey] = v
  })
  let data = {
    user: [{
      key: 'userId',
      label: '用户',
      tag: 'user'
    },
    // {
    //   key: 'roleId',
    //   label: '角色',
    //   tag: 'role'
    // },
    {
      key: 'roleId',
      label: '角色',
      tag: 'role'
    },
    {
      key: 'orgId',
      label: '机构',
      tag: 'org'
    }],
    budgetYear: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.budgetYear.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'applyOrgIds',
      label: '填报科室',
      tag: 'org'
    },
    {
      key: 'preOrgIds',
      label: '初审科室',
      tag: 'org'
    },
    {
      key: 'finalOrgId',
      label: '终审机构',
      tag: 'org'
    }],
    budgetDetail: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.budgetDetail.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'budgetYearStatus',
      label: '年度预算状态',
      selectData: statusGroupMap.budgetYear.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'budgetApplyOrgId',
      label: '提报科室',
      tag: 'org'
    },
    {
      key: 'applyOrgId',
      label: '初审科室',
      tag: 'org'
    },
    {
      key: 'fgldUserId',
      label: '分管领导',
      tag: 'user'
    },
    {
      key: 'finalOrgId',
      label: '终审科室',
      tag: 'org'
    }],
    purchaseBatch: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    }],
    purchaseBatchDetail: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseBatchDetail.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'purchaseBatchStatus',
      label: '采购批次状态',
      selectData: statusGroupMap.purchaseBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'orgId',
      label: '提报科室',
      tag: 'org'
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'leadUserId',
      label: '分管领导',
      tag: 'user'
    },
    {
      key: 'purchaseLeadUserId',
      label: '分管采购领导',
      tag: 'user'
    },
    {
      key: 'deanUserId',
      label: '分管院长',
      tag: 'user'
    }],
    purchaseMethodBatch: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseMethodBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    }],
    purchaseMethodBatchDetail: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseMethodBatchDetail.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'purchaseMethodBatchStatus',
      label: '采购方式批次状态',
      selectData: statusGroupMap.purchaseMethodBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'type',
      label: '类型',
      selectData: [{key: 'mini', label: '金额2000元以下'}, {key: 'small', label: '金额2000元以上'}, {key: 'big', label: '大型采购'}]
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'submitOrgId',
      label: '提报科室',
      tag: 'org'
    },
    {
      key: 'purchaseMethodLeadUserId',
      label: '分管领导',
      tag: 'user'
    },
    {
      key: 'deanUserId',
      label: '分管院长',
      tag: 'user'
    }],
    assetsRepairApply: [{
      key: 'status',
      label: '状态'
    },
    {
      key: 'type',
      label: '类型',
      selectData: [{key: 'mini', label: '价值2000元以下'}, {key: 'norm', label: '价值2000元以上'}]
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'applyOrgId',
      label: '申请科室',
      tag: 'org'
    },
    {
      key: 'leadUserId',
      label: '分管领导',
      tag: 'user'
    },
    {
      key: 'deanUserId',
      label: '分管院长',
      tag: 'user'
    }],
    assetsScrapApply: [{
      key: 'status',
      label: '状态'
    },
    {
      key: 'applyOrgId',
      label: '申请科室',
      tag: 'org'
    },
    {
      key: 'leadUserId',
      label: '分管领导',
      tag: 'user'
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'assetsLeadUserId',
      label: '分管资产领导',
      tag: 'user'
    }]
  }
  return data
}